import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme, } from '@mui/material';
import BasicBreadcrumbs from '../../components/shared/Breadcrumbs';
import Counter from '../../components/shared/Counter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardStats } from '../../store/actions/statsActions';

const colors = {
	dark: 'rgb(6 69 157)',
	light: 'rgb(54, 131, 239)'
}
interface STATS_COUNTER {
	navigator_total_visits: string;
	navigator_unique_visits: string;
	resource_copied: string;
	resource_total: string;
}
export default function DashboardPage(props: any) {

	const dispatch = useDispatch();
	const theme = useTheme();

	const [statsCounter, setStatsCounter] = useState<STATS_COUNTER | null>(null);
	const [pageloading, setPageLoading] = useState<boolean>(true);

	const separatorHiddenOnMD = useMediaQuery(theme.breakpoints.down('lg'));
	const separatorHiddenOnSM = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		dispatch(getDashboardStats({},
			(response) => {
				setStatsCounter(response);
				setPageLoading(false);
			},
			() => {
				setPageLoading(false);
			},
		))
	}, [])

	return (
		<>
		<BasicBreadcrumbs />
		<Box display={"flex"} alignItems={"center"}>
			<h1 className='pageTitle'>Dashboard</h1>
		</Box>

		{pageloading && 
			<Box marginTop={'50px'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
				<CircularProgress color='primary' sx={{marginRight:'5px'}} />
			</Box>
		}
		{!pageloading &&
			<Box marginTop={"35px"}>
				<Grid container spacing={2} >
					<Grid item xs={12} md={6} lg={3}>
						<Counter value={statsCounter?.navigator_total_visits ?? 0} separator={true} separatorHidden={separatorHiddenOnSM} color={colors.dark} description="total navigator visits" />
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<Counter value={statsCounter?.navigator_unique_visits} separator={true} separatorHidden={separatorHiddenOnMD} color={colors.light} description="unique navigator visits" />
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<Counter value={statsCounter?.resource_copied ?? 0} separator={true} separatorHidden={separatorHiddenOnSM} color={colors.dark} description="Resource Copied" />
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<Counter value={statsCounter?.resource_total} color={colors.light} description="New Resource added" />
					</Grid>
				</Grid>
			</Box>
		}
		</>
	)
}