import {
	Box,
	Card,
	CardContent,
	Typography,
	Pagination,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import theme from '../../themes/theme';
import { COLORS } from '../../enums/layout-enums';
import { useEffect, useState } from 'react';

export default function StaticSearchResources(props: any) {

	let style = {
		categoryLink: {
			fontSize: '0.875rem',
			fontWeight: '500',
			mt: 1,
		},
		categoryLinkWrap: {
			width: '100%',
		},
		categoryLinkWrapRight: {
			width: '50%',
			textAlign: 'right',
		},
	};

	return (

		<Box sx={{ pt: 8, pb: 3, ml:2, mr:2 }} justifyContent="center" alignItems="center">
			<Grid container spacing={4}>
						{/* card 1 */}
						<Grid xs={12} md={4} key={1}>
							<Box>
							{/* <ResourceCard
								color={theme.palette.violet.main}
								icon="truck"
								title="Lorem Ipsum dolor sit amet consectetur adipisicing elit"
								type="Lorem"
								phase="Ipsum"
								agency="Lorem ipsum"
								language="Spanish"
								text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat similique illo expedita! Consectetur odit iusto aperiam odio veritatis quisquam enim ad atque reiciendis, hic consequuntur iure. Qui voluptatibus doloremque maiores, molestias inventore a nulla temporibus assumenda. Quos, error, quo cumque earum nesciunt eveniet?"
							/> */}
								 <Card variant="outlined">
									<CardContent sx={{ p: 2, }}>
										<Typography
											color={COLORS.BLUE_BRAND_PRIMARY_DARK}
											sx={{
												fontSize: '1.125rem',
												mb: 1,
											}}
										>General Resources</Typography>
										<img
											src={process.env.PUBLIC_URL + "/images/category-1" + ".png"}
											alt=""
											style={{
												display: 'block',
												width: '100%', height: 'auto',
												borderRadius: 10,
											}}
										/>
										<Box sx={{ flexWrap: 'wrap', mt: 2, }}>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Aunt Bertha</Typography>
												<a href="https://www.findhelp.org" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>https://www.findhelp.org</u></a>
											</Box>
											<Box mt={2} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>2-1-1</Typography>
												<a href="https://www.211.org/about-us/your-local-211" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>https://www.211.org/about-us/your-local-211</u></a>
											</Box>
										</Box>
									</CardContent>
								</Card> 
							</Box>
						</Grid>
						{/* card 2 */}
						<Grid xs={12} md={4} key={1}>
							<Box>
								 <Card variant="outlined">
									<CardContent sx={{ p: 2, }}>
										<Typography
											color={COLORS.BLUE_BRAND_PRIMARY_DARK}
											sx={{
												fontSize: '1.125rem',
												mb: 1,
											}}
										>Clinical Trial All Cancers</Typography>
										<img
											src={process.env.PUBLIC_URL + "/images/category-2.png"}
											alt=""
											style={{
												display: 'block',
												width: '100%', height: 'auto',
												borderRadius: 10,
											}}
										/>
										<Box sx={{ flexWrap: 'wrap', mt: 2, }}>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>All Cancers</Typography>
												<a href="https://clinicaltrials.gov" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>National Cancer Institute</u></a>
											</Box>
										</Box>
									</CardContent>
								</Card> 
							</Box>
						</Grid>
						{/* card 3 */}
						<Grid xs={12} md={4} key={1}>
							<Box>
								 <Card variant="outlined">
									<CardContent sx={{ p: 2, }}>
										<Typography
											color={COLORS.BLUE_BRAND_PRIMARY_DARK}
											sx={{
												fontSize: '1.125rem',
												mb: 1,
											}}
										>Clinical Trials Specific Cancer</Typography>
										<img
											src={process.env.PUBLIC_URL + "/images/category-3.png"}
											alt=""
											style={{
												display: 'block',
												width: '100%', height: 'auto',
												borderRadius: 10,
											}}
										/>
										<Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, }}>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Colorectal Cancer</Typography>
												<a href="https://fightcolorectalcancer.org/facing-colorectal-cancer/colorectal-cancer-clinical-trials" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>Fight Colorectal Cancer</u></a>

											</Box>
											<Box mt={1} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Breast Cancer</Typography>
												<a href="https://www.komen.org/clinical-trials" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>Susan G Komen</u></a>
											</Box>
											<Box mt={1} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Lung Cancer</Typography>
												<a href="https://www.lungevity.org/for-patients-caregivers/navigating-your-diagnosis/treatment-options/clinical-trials" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>Lungevity</u></a>
											</Box>
											<Box mt={1} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Blood Cancer</Typography>
												<a href="https://www.lls.org/support-resources/information-specialists" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>Leukemia and Lymphoma Society</u></a>
											</Box>
											<Box mt={1} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Pancreatic Cancer</Typography>
												<a href="https://pancan.org/news/clinical-trial-finder-announcement" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>PANCAN</u></a>
											</Box>
											<Box mt={1} sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Hereditary Cancers</Typography>
												<a href="https://www.facingourrisk.org/research-clinical-trials/search" target="_blank" rel="noreferrer" className="titleText"><u style={{color:"#0069c2"}}>FORCE</u></a>
											</Box>
										</Box>
									</CardContent>
								</Card> 
							</Box>
						</Grid>
			</Grid>
			{/* <Grid sx={{mt:3}} container justifyContent="center" alignItems="center">
				<Pagination shape="rounded" count={5} page={page} onChange={(event, value) => setPage(value)} />
			</Grid> */}
		</Box>

	)
}